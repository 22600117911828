import { template as template_ae94493180a14515a58794bccddf1913 } from "@ember/template-compiler";
const WelcomeHeader = template_ae94493180a14515a58794bccddf1913(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
