import { template as template_afe2e5828cf24411a48d3fa8adc085a7 } from "@ember/template-compiler";
const SidebarSectionMessage = template_afe2e5828cf24411a48d3fa8adc085a7(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
